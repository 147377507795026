<template>
  <div id="app">
    <Container>
      <Header/>
      <router-view/>
      <Footer/>
    </Container>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Container from './components/Container.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Container,
    Footer
  }
}
</script>

<style lang="scss">
@import './assets/fonts/stylesheet.css';
body, html {
  font-family: 'MBCorpoSText';
  color:#ffffff;
  background-color: #000000;
  min-height: 100vh;
  margin:0;
  padding:0;
  font-size:16px;
  overflow-x: hidden;
}
*, :after, :before {
    box-sizing: border-box;
}
h1,h2,h3,h4,h5,p{
  margin:0px;
  font-weight: 400;
}
a{
  color: inherit;
  text-decoration: none;
}
h1,h2{
  font-family: 'MBCorpoATextCond';
  font-size:24px;
}
p{
  font-size:14px;
  span{
    font-size:12px;
  }
}

.form{
  margin:60px 0 50px 0;
  @media(max-width: 550px){
    margin:40px 0 30px 0;
  }
  &__header{
    margin:0 0 50px 0;
    text-align: center;
    @media(max-width: 550px){
      margin:0 0 30px 0;
    }
    h2{
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }
  &__inputs{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__input{
    max-width: calc((100% / 4) - 8px);
    width: 100%;
    margin: 0 0 30px 0;
    @media(max-width:1100px){
      max-width: calc((100% / 3) - 8px);
    }
    @media(max-width:960px){
      max-width: calc((100% / 2) - 8px);
      margin: 0 0 15px 0;
    }
    @media(max-width:550px){
      max-width: 100%;
    }
  }
  &__message{
    max-width: 100%;
  }
  &__accepts{
    font-size:12px;
    margin:30px 0;
    @media(max-width: 550px){
      margin:20px 0 30px 0;
    }
    &-text{
      margin: 20px 0;
      a{
        color: #00C4FF;
        text-decoration: underline;
        font-weight: 300;
        cursor: pointer;
      }
    }
    &-content{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      label{
        display: flex;
        align-items: center;
        margin:0 20px 5px 0;
        @media(max-width:450px){
          width: 100%;
        }
        &.error{
          input[type=checkbox]{
            border:1px solid red;
            &:after {
              border:1px solid red;
            }
          }
        }
      }
    }
  }
  &__button{
    position: relative;
    text-align: center;
    &-content{
      position: relative;
      display: inline-block;
      background-color: #ffffff;
      color: #000000;
      font-weight: 300;
      max-width: 150px;
      width: 100%;
      padding:10px 20px;
      z-index: 2;
      cursor: pointer;
    }
    &::after{
      position: absolute;
      display: block;
      content:'';
      width: 0px;
      height: 0px;
      left: 0px;
      right: 0px;
      bottom:-14px;
      margin: 0 auto;
      transition:all 0.6s;
    }
    &:hover{
      &::after{
        background-color: #5BBBED;
        width: 106px;
        height: 28px;
        filter: blur(35px);
        z-index: 0;
      }
    }
    &.sending{
      &:hover{
        &::after{
          display: none;
        }
      }
      &::before{
        position: absolute;
        display: block;
        content:'';
        left: 0px;
        right: 0px;
        top:0px;
        bottom:0px;
        margin: 0 auto;
        transition:all 0.6s;
        background-color: #2C2C2C;
        max-width: 150px;
        width: 100%;
        padding:10px 20px;
        z-index: 3;
      }
    }
  }
  .text-error{
    color:red;
    font-size:14px;
  }
  textarea{
    font-family: 'MBCorpoSText';
    width: 100%;
    background-color: #2C2C2C;
    color: #ffffff;
    border: 0px;
    padding: 12px 15px;
    margin-top: 7px;
    min-height: 67px;
    &:focus{
      outline: 0;
    }
  }
  input[type=text],select{
    font-family: 'MBCorpoSText';
    width: 100%;
    background-color: #2C2C2C;
    color: #ffffff;
    border: 0px;
    padding: 12px 15px;
    margin-top: 7px;
    font-size: 12px;
    appearance: none;
    &:focus{
      outline: 0;
    }
  }
  select{
    padding:12px 35px 12px 15px;
    background-image: url('./assets/arrow-down.svg');
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: center;
    cursor: pointer;
  }
  input[type=checkbox]{
    width: 19px;
    height: 19px;
    border: 1px solid #2C2C2C!important;
    background-color: #000000;
    margin:0 8px 0 0;
    &:before {
      position: relative;
      display: block;
      width: 19px;
      height: 19px;
      content: "";
      background: #000000;
    }
    &:after {
      position: relative;
      display: block;
      left: 0;
      top: -19px;
      width: 19px;
      height: 19px;
      border: 1px solid #2C2C2C;
      background-color: #000000;
      content: "";
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: 6px;
    }
    &:checked{
      &:after{
        background-image: url('./assets/check.svg');
      }
    }
  }
  ::placeholder{
    font-family: 'MBCorpoSText';
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
  }
}

.desktop{
  display: block;
  @media(max-width: 960px){
    display: none;
  }
}
.mobile{
  display: none;
  @media(max-width: 960px){
    display: block;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
